<template>
  <div class="device-manage-box">
    <Title :title="panelData.title" />
    <div class="device-list-box">
      <ul>
        <li
          v-for="item of panelData.list"
          :key="item.text"
          @click="handleUrlClick(item)"
        >
          <div class="icon">
            <img
              :src="item.icon"
              :alt="item.text"
              :style="`width: ${item.iconWid}`"
            />
            <i v-if="item.num">{{ item.num }}</i>
          </div>
          <h3>{{ item.text }}</h3>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Title from '@/components/Title'

export default {
  name: 'DevicePanel',
  props: {
    panelData: {
      type: Object,
      default() {},
    },
  },
  components: {
    Title,
  },
  data() {
    return {}
  },
  methods: {
    /**
     * url跳转
     * @param url
     */
    handleUrlClick(item) {
      if (item.url) {
        this.$router.push(item.url)
      } else {
        this.$emit('handleMenuClick', item)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.device-manage-box {
  width: 100%;
  padding-top: 0.5rem;
  .device-list-box {
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 25%;
        height: 4rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 0.6rem 0;
        .icon {
          @include wh(100%, 1.8rem);
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          img {
            width: 1.2rem;
          }
          i {
            position: absolute;
            right: 0.5rem;
            top: -0.6rem;
            background-color: #fa622e;
            padding: 0.06rem 0.3rem 0.1rem 0.3rem;
            font-size: 0.55rem;
            color: white;
            border-radius: 0.5rem;
          }
        }
        h3 {
          color: #333333;
          font-size: 0.7rem;
        }
      }
    }
  }
}
</style>
