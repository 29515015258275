import axios from '@/config/axios'

// 获取七牛云token
export const getQiNiuToken = () => axios({
  method: 'get',
  url: `/mobile/qiniu/getToken`
})

// 枚举类公共调用接口(枚举类类型值 1：设备状态 2：管理级别 3：故障等级 4：设备类型 5：设备来源 6：物料类型 7：产品类型)
export const getEnumsDetails = (enumType) => axios({
  method: 'get',
  url: `/enumTypeDetail/getEnumsDetails/${enumType}`
})

// 根据工号获取员工
export const getEmployeeUser = (employeeNo) => axios({
  method: 'get',
  url: `/users/getUser/${employeeNo}`
})

// 微信鉴权接口
export const getAuth = (url) => axios({
  method: 'get',
  url: `/mobile/mp/getAuth?url=` + url
})


// 查询人员
export const loadDeptWithUsersTrees = () => axios({
  method: 'get',
  url: `/dept/loadDeptWithUsersTrees`
})
