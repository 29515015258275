/**
 * 判断是否微信浏览器
 */

export const is_weixn = () => {
    const ua = navigator.userAgent.toLowerCase();
    if(ua.match(/MicroMessenger/i)=="micromessenger") {
        return true;
    } else {
        return false;
    }
}
