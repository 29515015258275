<template>
  <div class="main-menu-wrapper">
    <div
      class="menu-item"
      v-for="item of menuArr"
      :key="item.text"
      @click="handleMenuUrlClick(item.url)"
    >
      <div class="menu-icon" :style="`background: ${item.bgColor}`">
        <img
          :src="item.icon"
          :alt="item.text"
          :style="`width: ${item.imgWid},color:#fff`"
        />
        <i v-if="item.num">{{ item.num }}</i>
      </div>
      <div class="menu-text">{{ item.text }}</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast, Notify } from 'vant'
import HomeIcon1 from '@/assets/img/home/home-icon1.png'
import HomeIcon2 from '@/assets/img/home/home-icon2.png'
import HomeIcon3 from '@/assets/img/home/home-icon3.png'
import HomeIcon4 from '@/assets/img/home/home-icon4.png'

Vue.use(Toast, Notify)
export default {
  name: 'MainMenu',
  props: {
    alarmEvents: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      menuArr: [
        {
          icon: HomeIcon1,
          text: '设备监控',
          url: '/deviceMonitor',
          imgWid: '1.2rem',
          num: '',
          bgColor:
            'linear-gradient(0deg,rgba(75,153,255,1) 0%,rgba(84,182,252,1) 99%)',
        },
        {
          icon: HomeIcon2,
          text: '设备保养',
          url: '/maintain',
          imgWid: '1.4rem',
          num: '',
          bgColor:'linear-gradient(-90deg,rgba(78,224,155,1) 0%,rgba(66,219,162,1) 99%)',
        },
        {
          icon: HomeIcon3,
          text: '设备维修',
          url: '/deviceRepair',
          imgWid: '1.2rem',
          num: '',
          bgColor:
            'linear-gradient(-90deg,rgba(254,211,55,1) 0%,rgba(254,162,35,1) 99%)',
        },
        {
          icon: HomeIcon4,
          text: '告警事件',
          url: '/alarmEvent',
          imgWid: '1.1rem',
          num: this.alarmEvents,
          bgColor:
            'linear-gradient(0deg,rgba(255,121,110,1) 0%,rgba(253,143,140,1) 100%)',
        },
      ],
    }
  },
  methods: {
    /**
     * 菜单
     */
    handleMenuUrlClick(url) {
      if (url === '') {
        Notify({ type: 'danger', message: '该服务正在建设中' })
      } else {
        this.$router.push(url)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.main-menu-wrapper {
  @include wh(100%, 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 1rem 0 0.5rem 0;
  .menu-item {
    @include wh(25%, 100%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .menu-icon {
      @include wh(2.4rem, 2.4rem);
      border-radius: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img {
        width: 1.2rem;
      }
      i {
        position: absolute;
        right: -0.5rem;
        top: -0.6rem;
        background-color: #fa622e;
        padding: 0.06rem 0.3rem 0.1rem 0.3rem;
        font-size: 0.55rem;
        color: white;
        border-radius: 0.5rem;
      }
    }
    .menu-text {
      color: #333333;
      font-size: 0.7rem;
    }
  }
}
</style>
