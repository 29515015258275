<template>
  <div class="home-wrapper">
    <div class="home-banner-wrapper">
      <img :src="HeaderIcon" alt="banner" />
    </div>
    <div class="home-main-menu-wrapper">
      <MainMenu :alarmEvents="alarmEvents" />
    </div>
    <div class="device-manage-wrapper">
      <DevicePanel :panelData="deviceData" @handleMenuClick="handleMenuClick"/>
    </div>
    <div class="business-manage-wrapper">
      <DevicePanel
        :panelData="businessData"
        @handleMenuClick="handleMenuClick"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast, Notify } from 'vant'
import MainMenu from './MainMenu'
import { mapActions } from 'vuex'
import DevicePanel from './DevicePanel'
import { getInfoNum } from '@/api/home'
import { getAuth } from '@/api/common'
import { wxLogin, scanCode } from '@/utils/wx'
import { is_weixn } from '@/utils/brower'

import DeviceIcon1 from '@/assets/img/home/device-icon1.png'
import DeviceIcon2 from '@/assets/img/home/device-icon2.png'
import DeviceIcon3 from '@/assets/img/home/device-icon3.png'
import DeviceIcon4 from '@/assets/img/home/device-icon4.png'
import DeviceIcon5 from '@/assets/img/home/device-icon5.png'
import DeviceIcon6 from '@/assets/img/home/device-icon6.png'

import BusinessIcon1 from '@/assets/img/home/business-icon1.png'
import BusinessIcon2 from '@/assets/img/home/business-icon2.png'
import BusinessIcon3 from '@/assets/img/home/business-icon3.png'
import BusinessIcon4 from '@/assets/img/home/business-icon4.png'

import HeaderIcon from '@/assets/img/home/home-header-bg.jpg'

Vue.use(Toast, Notify)
export default {
  name: 'Home',
  components: {
    MainMenu,
    DevicePanel,
  },
  data() {
    return {
      showIdentityResult: false,
      HeaderIcon: HeaderIcon,
      alarmEvents: 0,
      checkLists: 0,
      maintainPlans: 0,
      deviceData: {
        title: '设备管理',
        list: [
          {
            icon: DeviceIcon1,
            text: '设备监控',
            url: '/deviceMonitor',
            num: '',
          },
          {
            icon: DeviceIcon2,
            text: '点检管理',
            url: '/check',
            num: this.checkLists,
            iconWid: '1.4rem',
          },
          {
            icon: DeviceIcon3,
            text: '设备台账',
            // url: '/device/ledger',
            url: '',
            num: '',
          },
          {
            icon: DeviceIcon4,
            text: '告警事件',
            url: '/alarmEvent',
            num: this.alarmEvents,
            iconWid: '1.6rem',
          },
          {
            icon: DeviceIcon5,
            text: '设备维修',
            url: '/deviceRepair',
            num: '',
          },
          {
            icon: DeviceIcon6,
            text: '保养计划',
            url: '/maintain',
            num: this.maintainPlans,
          },
        ],
      },
      businessData: {
        title: '业务',
        list: [
          {
            icon: BusinessIcon1,
            text: '审批',
            url: '/approval',
            num: '',
          },
          // {
          //     icon: BusinessIcon2,
          //     text: '扫一扫',
          //     url: '',
          //     num: ''
          // },
          {
              icon: BusinessIcon3,
              text: '订阅',
              url: '/subscribe',
              num: ''
          },
          // {
          //     icon: BusinessIcon4,
          //     text: '报表',
          //     url: '',
          //     num: ''
          // }
        ],
      },
    }
  },
  mounted() {
    if (is_weixn()) {
      this.getAuth()
    }

    this.getInfoNumRequest()
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    /**
     * 点击菜单
     */
    handleMenuClick(item) {
        console.log('--------')
      if (item.text === '扫一扫') {
        this.scanCode()
      } else if (item.url === '') {
        Notify({ type: 'danger', message: '该服务正在建设中' })
      }
    },
    /**
     * 获取消息数量
     */
    getInfoNumRequest() {
      this.setLoading(true)
      getInfoNum()
        .then((res) => {
          const { alarmEvents, checkLists, maintainPlans } = res
          this.alarmEvents = alarmEvents
          this.checkLists = checkLists
          this.maintainPlans = maintainPlans
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
    /**
     * 获取微信鉴权信息
     */
    getAuth() {
      const url = location.href
      getAuth(url).then((res) => {
        console.log(res)
        localStorage.setItem('js_device_mobile_wx', JSON.stringify(res))
        wxLogin(res)
      })
    },
    /**
     * 扫码
     */
    scanCode() {
      scanCode((res) => {
        const deviceName = this.getQueryVariable(res, 'deviceName')
        const deviceNo = this.getQueryVariable(res, 'deviceNo')

        if (deviceName && deviceNo) {
          this.$router.push({
            path: '/deviceRepair',
            query: {
              type: 'sao',
              deviceName,
              deviceNo,
            },
          })
        } else {
          Toast('扫描失败！')
        }
      })
    },
    /**
     * 获取url参数
     */
    getQueryVariable(url, variable) {
      const query = url
      const vars = query.split('&')
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        if (pair[0] == variable) {
          return pair[1]
        }
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';

.home-wrapper {
  position: relative;
  .home-banner-wrapper {
    @include wh(100%, 7rem);
    img {
      @include wh(100%, 100%);
    }
  }
  .home-main-menu-wrapper {
    position: absolute;
    left: 50%;
    margin-left: -45%;
    top: 5rem;
    @include wh(90%, 6rem);
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0 10px #d7d4d4;
  }
  .device-manage-wrapper {
    @include wh(100%, 11rem);
    box-sizing: border-box;
    padding: 0 5%;
    margin-top: 4rem;
  }
  .business-manage-wrapper {
    @include wh(100%, 10rem);
    box-sizing: border-box;
    padding: 0 5%;
    border-top: 0.5rem solid #f5f5f5;
  }
}
</style>
